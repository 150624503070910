import React, { ChangeEvent, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormSetFocus,
} from 'react-hook-form';
import { InputContainer } from '../styles';

interface InputTextProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  maxLength: number;
  caseInput?: 'lower' | 'upper' | 'normal';
  toLowerCase?: boolean | undefined;
  isDisabled?: boolean;
  control?: Control<FieldValues, any>;
  setFocus?: UseFormSetFocus<FieldValues>;
  alignRight?: boolean;
}

export const InputText: React.FC<InputTextProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  setFocus,
  isError,
  maxLength,
  caseInput = 'normal',
  toLowerCase = true,
  control,
  isDisabled = false,
  alignRight = false,
  ...rest
}) => {
  useEffect(() => {
    if (setFocus) {
      setFocus(name);
    }
  }, [setFocus]);

  const handleCursorPosition = (event: any) => {
    const { value, selectionStart, selectionEnd } = event.target;
    const lowerCaseValue = value.toLowerCase();

    const cursorPosition =
      selectionStart === selectionEnd ? selectionStart : selectionEnd;
    const cursorOffset = lowerCaseValue.length - value.length;
    const newCursorPosition = cursorPosition! + cursorOffset;

    return newCursorPosition;
  };

  const newHandleCursorPosition = (event: any, type: string | undefined) => {
    const { value, selectionStart } = event.target;

    event.target.value = value.toLowerCase();

    if (type === 'upper') {
      event.target.value = value.toUpperCase();
    }

    event.target.setSelectionRange(selectionStart, selectionStart);
  };

  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          {control ? (
            <Controller
              name={name}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <input
                    {...register(name)}
                    type="text"
                    maxLength={maxLength}
                    className={
                      isError
                        ? `form-control is-invalid ${
                            alignRight ? 'align-right' : ''
                          }`
                        : `form-control ${alignRight ? 'align-right' : ''}`
                    }
                    value={value || ''}
                    placeholder={placeholder}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (caseInput !== 'normal') {
                        newHandleCursorPosition(event, caseInput);
                        onChange(event.target.value);
                        return;
                      }
                      onChange(event.target.value);
                    }}
                    {...rest}
                  />
                );
              }}
            />
          ) : (
            <input
              {...register(name)}
              type="text"
              disabled={isDisabled}
              maxLength={maxLength}
              className={isError ? 'form-control is-invalid' : 'form-control'}
              placeholder={placeholder}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (toLowerCase !== undefined) {
                  if (!toLowerCase) {
                    const cursorPointer = handleCursorPosition(event);

                    setTimeout(() => {
                      if (document.activeElement === event.target) {
                        event.target.setSelectionRange(
                          cursorPointer,
                          cursorPointer,
                        );
                      }
                    }, 0);
                    event.target.value = event.target.value.toUpperCase();
                  } else {
                    const cursorPointer = handleCursorPosition(event);

                    setTimeout(() => {
                      if (document.activeElement === event.target) {
                        event.target.setSelectionRange(
                          cursorPointer,
                          cursorPointer,
                        );
                      }
                    }, 0);

                    event.target.value = event.target.value
                      .toLowerCase()
                      .trimStart();
                  }
                }
              }}
              {...rest}
            />
          )}
        </Form.Group>
      </InputContainer>
    </>
  );
};
