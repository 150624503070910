import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from '../styles';

interface InputTextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError?: boolean;
  maxLength: number;
  control: Control<FieldValues, any>;
  resize?: boolean;
}

export const InputTextArea: React.FC<InputTextAreaProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  maxLength,
  control,
  resize = true,
  ...rest
}) => {
  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          {name === 'des_cest' ? (
            <Controller
              name={name}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <textarea
                    style={{ resize: 'none', height: '12.5rem' }}
                    {...register(name)}
                    maxLength={maxLength}
                    className={
                      isError ? 'form-control is-invalid' : 'form-control'
                    }
                    value={value.toUpperCase().trimStart() || ''}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                      onChange(event.target.value);
                    }}
                    placeholder={placeholder}
                    {...rest}
                  />
                );
              }}
            />
          ) : (
            <Controller
              name={name}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <textarea
                    style={{
                      resize: resize ? 'vertical' : 'none',
                      textTransform: 'uppercase',
                    }}
                    {...register(name)}
                    maxLength={maxLength}
                    className={
                      isError ? 'form-control is-invalid' : 'form-control'
                    }
                    value={value || ''}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                      onChange(event.target.value);
                    }}
                    placeholder={placeholder}
                    {...rest}
                  />
                );
              }}
            />
          )}
        </Form.Group>
      </InputContainer>
    </>
  );
};
