import styled from 'styled-components';

export const InputContainer = styled.div`
  .form-group {
    position: relative;
    margin-top: 0.5rem;
  }
  .form-control.is-invalid {
    background-image: none;
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    border-bottom: 2px solid #cf1919;
    &:focus {
      box-shadow: none;
    }
    padding: 0.375rem 0.75rem;
  }

  .form-label {
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
    font-weight: 600;
    line-height: 1.3333333333333333;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    margin-bottom: 4px;
  }

  .select-container {
    display: flex;
    flex-direction: row;
    gap: 25px;
    .form-group {
      margin: 0px;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: right;
      width: 100%;
      input {
        height: 41px;
        background-color: var(
          --ds-background-subtleBorderedNeutral-resting,
          #fafbfc
        );
        border: 2px solid var(--ds-border-neutral, #dfe1e6);
        border-radius: 3px;
        font-size: 0.875rem;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
          'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;

        cursor: pointer;
        transition: background-color 200ms ease-in-out 0s,
          border-color 200ms ease-in-out 0s;

        &:hover {
          background-color: var(--ds-background-default, #ebecf0);
          border-color: var(--ds-border-neutral, #ebecf0);
        }
        &:focus {
          background-color: var(--ds-background-default, #ffffff);
          border-color: var(--ds-border-focus, #4c9aff);
          outline: none;
          box-shadow: none;
        }
        &:disabled {
          cursor: not-allowed;
          background-color: #e9ecef;
          border-color: var(--ds-border-neutral, #ebecf0);
          opacity: 1;
        }
      }
      .drop-indicator {
        position: absolute;
        margin-right: 5px;
        span {
          cursor: pointer;
          svg {
            color: var(--ds-text-mediumEmphasis, #42526e);

            &:hover {
              color: var(--ds-text-mediumEmphasis, #6b778c);
            }
          }

          .spinner {
            margin-right: 10px;
            margin-bottom: 2px;
            color: #c2a0f8;
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;

      & * {
        width: 100%;
      }
      .drop-indicator {
        width: auto;
      }
    }
  }

  ul {
    max-height: 300px;
    margin-top: 7px;
    padding: 8px 0px;
    width: 100%;
    position: absolute;
    background: #fff;
    border-radius: 4px;
    box-shadow: var(
      --ds-overlay,
      0 0 0 1px hsl(0deg 0% 0% / 10%),
      0 4px 11px hsl(0deg 0% 0% / 10%)
    );
    z-index: 1;
    box-sizing: border-box;

    overflow-y: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f0f0f0;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #cecece;
      border-radius: 0px 4px 4px 0px;
      transition: 0.2s;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b0b0b0;
    }
  }

  input[type='number'],
  input[type='weight'] {
    text-align: right;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin-left: 0.5rem;
  }
`;

export const Option = styled.li`
  min-height: 31px;
  line-height: 31px;
  padding: 0px 15px;
  color: var(--ds-text-highEmphasis, #172b4d);
  font-size: inherit;

  cursor: default;
  &.selected {
    color: var(--ds-text-selected, #0052cc);
    background-color: var(--ds-background-selected-hover, #deebff);
    box-shadow: inset 2px 0px 0px var(--ds-text-selected, #0052cc);

    &:hover {
      color: var(--ds-text-selected, #0052cc);
      background-color: var(--ds-background-selected-hover, #deebff);
      box-shadow: inset 2px 0px 0px var(--ds-text-selected, #0052cc);
    }
  }
  &.not-found {
    text-align: center;
    color: #aeafb8;
  }
  &:hover:not(.not-found) {
    min-height: 31px;
    line-height: 31px;
    padding: 0px 15px;
    color: var(--ds-text-highEmphasis, #172b4d);
    box-shadow: inset 2px 0px 0px var(--ds-text-selected, #0052cc);
    background-color: var(--ds-background-subtleNeutral-resting, #f4f5f7);
    font-size: inherit;
  }
  .spinner {
    margin-right: 10px;
    margin-bottom: 2px;
    color: #c2a0f8;
  }
`;
