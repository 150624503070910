import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { InputContainer } from '../styles';

interface InputTextProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  isError: boolean;
  maxLength: number;
  control?: Control<FieldValues, any>;
}

export const InputText2: React.FC<InputTextProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  maxLength,
  control,
  ...rest
}) => {
  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <input
                  {...register(name)}
                  type="text"
                  maxLength={maxLength}
                  className={
                    isError ? 'form-control is-invalid' : 'form-control'
                  }
                  value={value}
                  placeholder={placeholder}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value.toUpperCase());
                  }}
                  {...rest}
                />
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
