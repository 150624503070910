import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Content, Option } from '../../styles';
import { ListProps } from '../../types';

export const ListaUf: React.FC<ListProps> = ({
  listaWidth,
  listaHeight,
  inputRef,
  setIsOpen,
  filter,
  setFilter,
  selected,
  setSelected,
  options,
  changeSelected,
}) => {
  const ITEM_TO_RENDER = 15;

  const [renderItem, setRenderItem] = useState(ITEM_TO_RENDER);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const loadMore = useCallback(
    () => setRenderItem((prev) => prev + ITEM_TO_RENDER),
    [],
  );

  const handleScroll = useCallback(() => {
    if (
      Number(wrapperRef.current?.scrollTop) +
        Number(wrapperRef.current?.clientHeight) >=
      Number(wrapperRef.current?.scrollHeight)
    ) {
      loadMore();
    }
  }, [loadMore]);

  useEffect(() => {
    const currentWrapperRef = wrapperRef.current;

    if (currentWrapperRef) {
      currentWrapperRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentWrapperRef) {
        currentWrapperRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, wrapperRef]);

  const useCloseOptions = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (inputRef) inputRef.current.value = '';
          setFilter('');
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useCloseOptions(wrapperRef);

  const handleClick = useCallback(
    (option: { label: string; value: number | string }) => {
      setSelected(option);
      changeSelected(option);
      setIsOpen(false);
    },
    [changeSelected, setIsOpen, setSelected],
  );

  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      if (ev.key === 'ArrowDown' && wrapperRef.current && inputRef.current) {
        inputRef.current.blur();
        wrapperRef.current.focus();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [inputRef]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();

        const currentIndex = options.findIndex(
          (option) => option.value === selected?.value,
        );

        let nextIndex;
        if (event.key === 'ArrowUp') {
          nextIndex =
            currentIndex === 0 ? options.length - 1 : currentIndex - 1;
        } else {
          nextIndex =
            currentIndex === options.length - 1 ? 0 : currentIndex + 1;
        }

        setSelected(options[nextIndex]);

        const selectedElement: any =
          wrapperRef?.current?.querySelector('.selected');
        if (selectedElement) {
          const wrapperRect = wrapperRef?.current?.getBoundingClientRect();
          const selectedRect = selectedElement.getBoundingClientRect();

          if (wrapperRect) {
            const offset = (wrapperRect.height - selectedRect.height) / 2;
            const scrollToTop = selectedElement.offsetTop - offset;

            if (event.key === 'ArrowUp' && nextIndex === options.length - 1) {
              wrapperRef?.current?.scrollTo({
                top: wrapperRef?.current?.scrollHeight - wrapperRect.height,
              });
            } else if (event.key === 'ArrowDown' && nextIndex === 0) {
              wrapperRef?.current?.scrollTo({
                top: 0,
              });
            } else {
              wrapperRef?.current?.scrollTo({
                top: scrollToTop,
              });
            }
          }
        }
      }

      if (event.key === 'Enter') {
        handleClick(selected);
      }
    },
    [handleClick, options, selected, setSelected],
  );

  const renderOptions = useCallback(() => {
    if (options !== undefined) {
      if (options.length <= 0) {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }
      const newOptions = options.filter((option) =>
        option.label
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .includes(filter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')),
      );
      if (newOptions.length <= 0) {
        return (
          <Option key={nanoid()} className="not-found">
            Nenhum registro encontrado.
          </Option>
        );
      }

      return (
        <InfiniteScroll
          dataLength={renderItem}
          next={loadMore}
          hasMore={renderItem < newOptions.length}
          loader={<small>Carregando...</small>}
          height={listaHeight}
        >
          {newOptions.slice(0, renderItem).map((option) => (
            <Option
              key={String(option?.value)}
              title={option.label}
              className={selected?.value === option?.value ? 'selected' : ''}
              onClick={() => {
                handleClick(option);
                if (inputRef) inputRef.current.value = option.label;
                setFilter('');
              }}
              onKeyPress={() => {
                handleClick(option);
                if (inputRef) inputRef.current.value = option.label;
                setFilter('');
              }}
            >
              {option.label}
            </Option>
          ))}
        </InfiniteScroll>
      );
    }
    return <Option className="not-found">Nenhum registro encontrado.</Option>;
  }, [
    filter,
    handleClick,
    inputRef,
    listaHeight,
    loadMore,
    options,
    renderItem,
    selected?.value,
    setFilter,
  ]);

  return (
    <Content listaWidth={listaWidth}>
      <div
        role="listbox"
        aria-label="Lista de Opções"
        ref={wrapperRef}
        style={{ height: listaHeight, listStyle: 'none' }}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        className="ulist"
      >
        {options && renderOptions()}
      </div>
    </Content>
  );
};
