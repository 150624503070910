import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { SelectType } from '../types';
import { findUfByLabel } from './findUfByLabel';

export const processInput = (
  value: string,
  selected: SelectType | null,
  name: string,
  options: SelectType[],
  setValue?: UseFormSetValue<FieldValues>,
): void => {
  if (value.length > 0 && value.length <= 2 && selected === null) {
    const uf = findUfByLabel(value, options);
    if (uf && setValue) setValue(name, uf);
  }
};
