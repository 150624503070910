import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LojaProps } from './protocols';
import { InputContainer } from './styles';
import { Form, Spinner } from 'react-bootstrap';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import api from '~/services/api';
import useDebounce from '~/hooks/useDebounce';
import { Lista } from './Lista';

/**
 * @param {LojaProps} Loja
 */

export const Loja: React.FC<LojaProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  control,
  changeSelected,
  getValues,
  retriever = null,
  ...rest
}: LojaProps) => {
  /**
   * debouncedFn
   * Hook de Debounce usado para efetuar a busca após digitação do usuário
   */
  const { debouncedFn } = useDebounce();
  /**
   * inputRef
   * Ref do input utilizado para setar a label da opção selecionada no input
   */
  const inputRef: any = useRef(null);
  /**
   * selected, setSelected
   * Opção selecionada da lista
   */
  const [selected, setSelected] = useState<any>({
    label: undefined,
    value: undefined,
  });

  /**
   * isOpen
   * Renderiza ou não a lista
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  /**
   * filter
   * Filtro passado para a api
   */
  const [filter, setFilter] = useState<string>('');
  /**
   * limit
   * Quantidade de registros buscados por vez na api
   */
  const limit = 10;
  /**
   * fetchData
   * Método que se comunica com a api
   */

  useEffect(() => {
    if (getValues) {
      const value = getValues(name);
      if (!value && selected.value) {
        changeSelected(selected);
      }
      if (
        value &&
        value.label &&
        value.value &&
        (value.label !== selected.label || value.value !== selected.value)
      ) {
        setSelected(value);
        changeSelected(value);
      }
    }
  }, [name, changeSelected, selected.value]);

  const fetchData = useCallback(
    async ({ pageParam = 1 }): Promise<any> => {
      try {
        const cache_lojas: any = queryClient.getQueryData('cache_lojas');
        if (cache_lojas) {
          handleCacheData(cache_lojas);
          return cache_lojas;
        }

        const dataFields = await fetchFromAPI(pageParam);
        const options = transformDataFields(dataFields);
        queryClient.setQueryData('cache_lojas', {
          ...dataFields,
          data: options,
        });
        return {
          ...dataFields,
          data: options,
        };
      } catch (error: any) {
        handleError(error);
        return getDefaultResponse();
      }
    },
    [changeSelected, filter, isOpen],
  );

  const handleCacheData = (cache_lojas: any) => {
    if (!isOpen && !selected.value) {
      const options = cache_lojas.data || [];
      const lojaPadrao = options.find((loja: any) => loja.flg_padrao === true);
      changeSelected(lojaPadrao || options[0] || {});
    }
  };
  const fetchFromAPI = async (pageParam: number) => {
    const { data } = await api.get(`/lojas/usuario`, {
      params: {
        page: pageParam,
        limit,
        filter,
      },
    });
    return data;
  };
  const transformDataFields = (dataFields: any) => {
    return dataFields.data
      .filter((d: any) => !d.flg_inativo)
      .map((dt: any) => ({
        value: dt.cod_loja,
        label: `${dt.des_loja} (${dt.des_cidade})`,
        ...dt,
      }));
  };
  const handleError = (error: any) => {
    const errorMessage = error.data?.message || String(error);
    toast.error(errorMessage);
  };
  const getDefaultResponse = () => ({
    count: '0',
    data: [],
    fields: [],
    pagination: {
      lastPage: 0,
      prevPage: 1,
      startPage: 1,
    },
    success: true,
  });

  /**
   * useInfiniteQuery
   * Hook do react-query para listagem infinita de dados
   */
  const queryClient = useQueryClient();
  const { data, hasNextPage, fetchNextPage, isFetching, refetch } =
    useInfiniteQuery(name, ({ pageParam = 1 }) => fetchData({ pageParam }), {
      refetchOnWindowFocus: false,
    });

  return (
    <InputContainer>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field: { value } }) => {
            if (inputRef && inputRef.current) {
              /** A condicional abaixo limpa o input */
              if (
                value.label !== selected.label &&
                inputRef.current.placeholder !== placeholder
              ) {
                inputRef.current.placeholder = placeholder;
              }

              if (value.label) {
                if (value.value) {
                  inputRef.current.placeholder = `${value.value} - ${value.label}`;
                } else {
                  inputRef.current.placeholder = `${value.label}`;
                }
              }
              if (!value.label) {
                inputRef.current.placeholder = placeholder;
              }
            }
            return (
              <>
                <div className="select-container">
                  <label>
                    <input
                      {...register(name)}
                      type="text"
                      className={
                        isError ? 'form-control is-invalid' : 'form-control'
                      }
                      placeholder={placeholder}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        event.target.value = event.target.value.toUpperCase();
                        setFilter(event.target.value.toUpperCase());
                        if (event.target.value === '') {
                          queryClient.removeQueries(name);
                        }
                        debouncedFn(() => refetch(), 500);
                      }}
                      autoComplete="off"
                      onClick={(event: any) => {
                        if (selected.label) {
                          event.target.placeholder = selected.label;
                        }
                        event.target.value = '';
                        setIsOpen(true);
                      }}
                      ref={inputRef}
                      {...rest}
                    />
                    <div className="drop-indicator">
                      <span role="img" aria-label="open">
                        {filter && isFetching ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            className="spinner"
                          />
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            role="presentation"
                          >
                            <path
                              d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                              fill="currentColor"
                              fillRule="evenodd"
                            />
                          </svg>
                        )}
                      </span>
                    </div>
                  </label>
                </div>
                {isOpen && (
                  <Lista
                    inputRef={inputRef}
                    setIsOpen={setIsOpen}
                    setFilter={setFilter}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    data={data}
                    selected={selected}
                    setSelected={setSelected}
                    isFetching={isFetching}
                    queryName={name}
                    changeSelected={changeSelected}
                    queryClient={queryClient}
                  />
                )}
              </>
            );
          }}
        />
      </Form.Group>
    </InputContainer>
  );
};
