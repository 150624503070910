/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { UfProps } from '~/pages/Loja/protocols';
import api from '~/services/api';
import { ListaUf } from './components/ListaUf';
import { InputContainer } from './styles';
import { InputUfProps, SelectType } from './types';
import { processInput } from './utils/processInput';

export const InputUf: React.FC<InputUfProps> = ({
  label = 'UF',
  ListaWidth = '100%',
  listaHeight = 'none',
  placeholder = '',
  name,
  register,
  isError,
  control,
  changeSelected,
  isDisabled = false,
  autoComplete = 'new-off',
  setValue,
  ...rest
}) => {
  const [selected, setSelected] = useState<SelectType | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [ufs, setUfs] = useState<SelectType[]>([]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/uf');

      if (data.success) {
        const opt: SelectType[] = data.data.map((uf: UfProps) => ({
          label: uf.des_sigla?.toUpperCase(),
          value: uf.cod_uf_ibge,
        }));

        setUfs(opt);
      }
    })();
  }, []);

  const handleInputClick = useCallback(() => setIsOpen(true), [setIsOpen]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toUpperCase();

      if (inputRef.current) inputRef.current.value = value;
      processInput(value, selected, name, ufs, setValue);

      setFilter(value);
      setSelected(null);
    },
    [name, selected, setValue, ufs],
  );

  const handleInputKeyDown = useCallback(
    (event) => {
      if (event.key === 'ArrowDown' && inputRef.current) {
        inputRef.current.blur();
        setIsOpen(true);
      }
    },
    [setIsOpen],
  );

  useEffect(() => {
    const closeList = (ev: KeyboardEvent) => {
      if (inputRef.current && (ev.key === 'Escape' || ev.key === 'Tab')) {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', closeList);
    return () => window.removeEventListener('keydown', closeList);
  }, []);

  return (
    <InputContainer>
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field }: { field: { value: SelectType } }) => {
            const inputValue = (field.value?.label || selected?.label) ?? '';

            if (inputRef.current?.value === '') {
              const value = inputValue === '' ? placeholder : inputValue;
              inputRef.current.placeholder = value;
            }

            if (
              inputRef.current?.placeholder &&
              field.value?.label === undefined
            ) {
              inputRef.current.placeholder = placeholder;
              inputRef.current.value = filter;
            }

            return (
              <>
                <label className="select-container">
                  <input
                    {...register(name)}
                    disabled={isDisabled}
                    type="text"
                    className={
                      isError ? 'form-control is-invalid' : 'form-control'
                    }
                    placeholder={placeholder || inputValue}
                    onChange={handleInputChange}
                    autoComplete="off"
                    onClick={handleInputClick}
                    onKeyDown={handleInputKeyDown}
                    ref={inputRef}
                    {...rest}
                  />
                  <div className="drop-indicator">
                    <span role="img" aria-label="open">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        role="presentation"
                      >
                        <path
                          d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                          fill="currentColor"
                          fillRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                </label>
                {isOpen && (
                  <ListaUf
                    listaWidth={ListaWidth}
                    listaHeight={listaHeight}
                    options={ufs}
                    inputRef={inputRef}
                    setIsOpen={setIsOpen}
                    filter={filter}
                    setFilter={setFilter}
                    selected={selected}
                    setSelected={setSelected}
                    changeSelected={changeSelected}
                  />
                )}
              </>
            );
          }}
        />
      </Form.Group>
    </InputContainer>
  );
};
